import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, ContentrArea } from '@ttstr/components';

const SellOut: React.FC = () => {
  return (
    
    <article className="sell-out-wrapper">
      <Helmet>
        <title>FELIX LOBRECHT – SELL OUT</title>
        <body className="sell-out inverse-navbar" />
      </Helmet>
      <Container>
        <div className="d-flex justify-content-center align-items-center h-100">
          <div>
            <img
              src={require('./assets/images/fl.png')}
              alt="FELIX LOBRECHT - SELL OUT"
              className="w-100 img-fluid"
            />
          </div>
          <div>
            <img
              src={require('./assets/images/so910.gif')}
              alt="FELIX LOBRECHT - SELL OUT"
              className="w-100 img-fluid"
            />
          </div>
          <div className="mt-5"><a href="/tickets">Weiter zum Shop →</a></div>
          <div className="mt-2"><a href="https://cdn.tickettoaster.de/kraken/fl_sellout_newsletter.html">Nachricht lesen</a></div>
        </div>
      </Container>
    </article>
  );
};

export default SellOut;
