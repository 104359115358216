import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Alert from 'reactstrap/lib/Alert';
import { Link } from 'react-router-dom';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

import { receiveTickets } from '@ttstr/actions';
import { Container, LoadingSpinner, useIntl, ContentrArea } from '@ttstr/components';
import { AppState } from '@ttstr/reducers';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';
import Masonry from './Masonry';

import { useRef } from 'react';


const TicketListing: React.FC = () => {
  const { t } = useTranslation();
  const { loading, tickets } = useShallowEqualSelector(mapStateToProps);
  const { receiveTickets } = useActions(mapDispatchToProps);
  const { language } = useIntl();

  React.useEffect(() => {
    receiveTickets();
  }, [language]);

  return (
    <article className="ticket-listing list-page mb-5">
      <Helmet>
        <title>{t(`TICKETLISTING.TITLE`)}</title>
        <body className="ticket-listing-page inverse-navbar" />
      </Helmet>

      {/* <Container className="stage mb-3">
        <div className="row">
          <div className="col-12">
            <a href="https://www.constantin-film.de/sonne-und-beton-kinotour" target="_blank" rel="noreferrer">
              <img
                src={require('./assets/images/sb_banner.png')}
                alt="SONNE UND BETON KINOTOUR"
                className="w-100 img-fluid"
              />
            </a>
          </div>
        </div>
      </Container> */}

      <div className="hero-image d-flex justify-content-center mb-3">
        <Container className='h-100 d-flex justify-content-center align-items-center'>
          <img
            src={require('./assets/images/so_main_orange.png')}
            alt="Felix Lobrecht – SELL OUT"
            title="Felix Lobrecht – SELL OUT"
            className="hero-image-overlay"
            loading="lazy"
          />
        </Container>
      </div>
      <section id="listing">
        <Container>
          <ContentrArea id="before-listing" />
          {/* <h1 className="text-center mb-4">{t(`PRODUCTS.TITLE`)}</h1> */}
        </Container>

        <Container className="listing-filter-buttons-container">
          <Row className="mb-3 mt-5 d-flex align-items-center justify-content-center listing-buttons-container">
            <Col sm="12" md="12" lg="12" className="mt-2">
              <Link 
                to="/tickets"  
                className="btn btn-lg btn-primary btn-tickets h-100 mr-2 mb-2"
                onClick={() => {
                  const element = document.getElementById('listing');
                  if ( !element ) return;
                  window.scroll({
                    top: element.offsetTop - 100,
                    behavior: 'smooth'
                  }) 
                }}  
              >
                Alle Termine
              </Link>
            {/* <Col sm="12" md="6" lg="3" className="mt-3">
              <Link 
                to="/tickets?tagId=8179"  
                className="btn btn-lg btn-primary btn-tickets h-100"
                onClick={() => {
                  const element = document.getElementById('listing');
                  if ( !element ) return;
                  window.scroll({
                    top: element.offsetTop - 100,
                    behavior: 'smooth'
                  }) 
                }}  
              >                
                Tryouts
              </Link>
            </Col> */}
              <Link 
                to="/tickets?tagId=8180"  
                className="btn btn-lg btn-primary btn-tickets h-100  mr-2 mb-2"
                onClick={() => {
                  const element = document.getElementById('listing');
                  if ( !element ) return;
                  window.scroll({
                    top: element.offsetTop - 100,
                    behavior: 'smooth'
                  }) 
                }}  
              >   
                Tour 2025
              </Link>
              <Link 
                to="/tickets?tagId=8181"  
                className="btn btn-lg btn-primary btn-tickets h-100  mr-2 mb-2"
                onClick={() => {
                  const element = document.getElementById('listing');
                  if ( !element ) return;
                  window.scroll({
                    top: element.offsetTop - 100,
                    behavior: 'smooth'
                  }) 
                }}  
              >
                Tour 2026
              </Link>
            </Col>
          </Row>
        </Container>

        <Container>
          {loading ? ( // if loading
            <LoadingSpinner label={t(`LOADING.TICKETS`)} />
          ) : tickets.length === 0 ? ( // if no tickets
            <Alert color="info">{t(`MASONRY.NO_ITEMS`)}</Alert>
          ) : (
            // else show tickets
            <Masonry
              id="ticket-listing"
              products={tickets}
              type="list"
              filters="off"
              showLocation
              showCity
            />
          )}
        </Container>

        <Container>
          <div className="alert alert-fl mt-4">
            <p>Wenn ihr euch Tickets für eine Show in eurer Nähe wirklich (!) nicht leisten könnt, schreibt einfach 'ne E-Mail an socialticket@standup44.de und wir finden einen Weg. Hatte mein Leben lang keine Kohle und weiß genau, wie scheiße das ist.</p>
            <p className='mb-0 mt-4'>Love ❤️</p>
          </div>
          <ContentrArea id="after-listing" />
        </Container>
      </section>
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const { tickets, loading } = state.Tickets;
  return {
    tickets,
    loading,
  };
};

const mapDispatchToProps = {
  receiveTickets,
};

export default React.memo(TicketListing);
