import React from 'react';

import { useTranslation, Trans } from 'react-i18next';

import Button from 'reactstrap/lib/Button';

import { requestWaitingHeartBeat } from '@ttstr/api/heartbeat';
import { useMounted } from '@ttstr/utils';
import LoadingSpinner from '@ttstr/components/Loading/LoadingSpinner';

function getTimeRemaining(t: number) {
  if (t < 0) return '00:00:00';

  const seconds = Math.floor(t % 60);
  const minutes = Math.floor((t / 60) % 60);
  const hours = Math.floor((t / 60 / 60) % 24);
  const days = Math.floor(t / 60 / 60 / 24);
  return (
    (days > 0 ? days + ':' : '') +
    (hours < 10 ? '0' : '') +
    hours +
    ':' +
    (minutes < 10 ? '0' : '') +
    minutes +
    ':' +
    (seconds < 10 ? '0' : '') +
    seconds
  );
}

export const G8Manager: React.FC = () => {
  const isMounted = useMounted();
  const { t } = useTranslation();
  const [heartBeatTimeoutId, setHeartBeatTimeoutId] = React.useState<NodeJS.Timeout>(null);
  const [loading, setLoading] = React.useState(true);
  const [initialized, setInitialized] = React.useState(false);
  const [currentPosition, setCurrentPosition] = React.useState<number>();
  const [activateAt, setActivateAt] = React.useState<number>();
  const intervalId = React.useRef<NodeJS.Timeout>();
  const [diff, setDiff] = React.useState<number>();
  const [message, setMessage] = React.useState<string>();

  React.useEffect(() => {
    clearInterval(intervalId.current);
    if (activateAt) intervalId.current = setInterval(() => setDiff(activateAt - new Date().getTime() / 1000), 1000);
    return () => clearInterval(intervalId.current);
  }, [activateAt]);

  const startWaitingHeartBeat = React.useCallback(async () => {
    clearTimeout(heartBeatTimeoutId);
    const beat = await requestWaitingHeartBeat();

    /* debug output */
    // const beat = {
    //   token: '123',
    //   position: '42',
    //   activate_at: new Date(),
    //   queue_heartbeat: 10000,
    //   message: 'Test Message',
    // };

    let nextBeat = beat.queue_heartbeat;

    if (beat.activate_at) {
      setActivateAt(beat.activate_at.getTime() / 1000);
      const diff = (beat.activate_at.getTime() - new Date().getTime()) / 1000;
      nextBeat = Math.min(diff * 1000, nextBeat);
      setDiff(diff);
    } else {
      setActivateAt(null);
      setDiff(null);
    }

    setMessage(beat.message !== '' ? beat.message : null);

    if (beat.position !== '' && Number(beat.position) > -1) {
      setCurrentPosition(Number(beat.position) + 1);
    } else {
      setCurrentPosition(null);
    }

    if (beat.activate_at || (beat.position !== '' && Number(beat.position) > -1)) {
      setHeartBeatTimeoutId(setTimeout(startWaitingHeartBeat, nextBeat));
    }
  }, []);

  React.useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        await startWaitingHeartBeat();
        if (isMounted.current) setInitialized(true);
      } finally {
        if (isMounted.current) setLoading(false);
      }
    })();
    return () => {
      clearTimeout(heartBeatTimeoutId);
    };
  }, []);

  if (loading) return <LoadingSpinner />;

  if (!initialized) return <div className="text-danger">{t('G8.ERROR')}</div>;

  if (activateAt && diff > 0) {
    return (
      // <div>
      //   <h1 className="text-center mb-4 g8-countdown">
      //     <Trans i18nKey="G8.COUNTDOWN">
      //       {/* eslint-disable-next-line react/jsx-no-literals */}
      //       <small className="mt-2">{t('G8.COUNTDOWN_START_IN')}</small>
      //       <span className="g8-time-remaining">{{ remaining: getTimeRemaining(diff) }}</span>
      //     </Trans>
      //   </h1>
      // </div>
      <div className="countdown-wrapper d-flex align-items-center flex-column">
        <span className="countdown d-inline-block">Tickets in {getTimeRemaining(diff)}</span>
        <a href="https://cdn.tickettoaster.de/kraken/fl_sellout_newsletter.html" target="_blank"><span className="countdown d-inline-block mt-3">Nachricht lesen</span></a>

        {/* TODO: LOGIN */}
        {/* <p className="lead mt-5">Du hast einen <b>Gewinn-Code</b> aus der #AYCE Box? Gib diesen hier ein, um bereits vor dem offiziellen VVK-Start am 9.10. um 18 Uhr Tickets kaufen zu können:</p>
        <form action="/early-access" method="get" className="col-12 col-md-6">
          <div className="input-group input-group-lg mb-3">
            <input type="text" className="form-control" placeholder="Abc123-Efg456" aria-label="Abc123-Efg456" aria-describedby="button-addon2" name="code" id="code" />
            <button className="btn btn-lg btn-success" type="submit" id="button-addon2"><i className="far fa-sign-in"></i></button>
          </div>
        </form> */}
      </div>
    );
  }

  if (currentPosition) {
    return (
      // <div>
      //   <h1 className="text-center mb-4">
      //     {t('G8.PLEASE_WAIT_TITLE')}
      //     <small className="mt-2 mb-4">{t('G8.PLEASE_WAIT_SUBTITLE')}</small>
      //   </h1>
      //   <h1>{t('G8.YOUR_CURRENT_POSITION', { position: currentPosition })}</h1>
      //   <div className="alert alert-danger mt-4 text-uppercase">
      //     <b>{t('G8.ATTENTION')}</b> {t('G8.DO_NOT_RELOAD_INFO')}
      //   </div>
      // </div>
      <div className="wait-wrapper d-flex align-items-center flex-column">
        {message ? (
          <div className="card mb-5 text-white bg-danger message-wrapper col-12">
            <div className="card-body">
              <p className="card-text lead">{message}</p>
            </div>
          </div>
        ) : null}
        <span className="position d-inline-block">
          Du bist an Position #<span className="gate-position">{currentPosition}</span>
        </span>
        <p className="lead mt-5 fw-400">
          Bitte habe ein wenig Geduld, bis Du auf den Shop weitergeleitet wirst.
        </p>
        <div className="mt-2 mb-4 text-center small col-md-6">
          <strong>Lade diese Seite nicht neu, sonst wird deine
          Position frei und Du wirst wieder hinten angestellt.</strong>
        </div>
        <p className="lead fw-400">
          <a className="btn btn-primary" href="https://www.ticketcorner.ch/artist/felix-lobrecht/" target="_blank">Tickets für die Schweiz</a>
        </p>
      </div>
    );
  }

  return (
    // <div>
    //   <h1 className="text-center mb-4">{t('G8.CONTINUE_TITLE')}</h1>
    //   <Button tag="a" href="/" size="lg" color="danger">
    //     {t('G8.CONTINUE_CTA')}
    //   </Button>
    // </div>
    <div className="go-wrapper">
      <p className="lead fw-400">
        <b>Vielen Dank!</b> Jetzt bist Du endlich an der Reihe!
      </p>
      <a href="/" className="btn btn-success btn-lg" tabIndex={-1} role="button" aria-disabled="true">
        Jetzt Tickets kaufen
      </a>
    </div>
  );
};

export default React.memo(G8Manager);
