import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import Button from 'reactstrap/lib/Button';
import Card from 'reactstrap/lib/Card';
import CardBody from 'reactstrap/lib/CardBody';
import CardFooter from 'reactstrap/lib/CardFooter';
import CardText from 'reactstrap/lib/CardText';
import CardTitle from 'reactstrap/lib/CardTitle';
import Alert from 'reactstrap/lib/Alert';
import Collapse from 'reactstrap/lib/Collapse';

import { getLocationFromProduct } from '@ttstr/reducers/locations';
import DateComponent from '@ttstr/components/Intl/DateComponent';
import { useProductDetailLink, useProductDetails } from '@ttstr/components/ProductDetail';
import { SushiProps } from '@ttstr/components/Sushi/Sushi';

import { receiveTickets } from '@ttstr/actions';
import { Container, LoadingSpinner, Masonry, useIntl, ContentrArea } from '@ttstr/components';
import { AppState } from '@ttstr/reducers';
import { useActions, useShallowEqualSelector, isAbsoluteURL, useToggle } from '@ttstr/utils';

const FAQ: React.FC = () => {
  const { t } = useTranslation();
  const { loading, tickets } = useShallowEqualSelector(mapStateToProps);
  const { receiveTickets } = useActions(mapDispatchToProps);
  const { language } = useIntl();

  React.useEffect(() => {
    receiveTickets();
  }, [language]);

  return (
    <article className="faq-listing list-page my-5">
      <Helmet>
        <title>{'FAQ'}</title>
        <body className="faq-listing-page inverse-navbar" />
      </Helmet>

      <Container className="stage mb-5 p-0">
        <img
          src={require('./assets/images/ayce_logo.png')}
          alt="FELIX LOBRECHT - ALL YOU CAN EAT"
          className="w-100 img-fluid"
        />
      </Container>

      <Container>
        <h1 className="text-center mb-4">{'FAQ'}</h1>
        <ContentrArea id="before-faq-listing" />
      </Container>

      <Container>
        {loading ? ( // if loading
          <LoadingSpinner label={t(`LOADING.TICKETS`)} />
        ) : tickets.length === 0 ? ( // if no tickets
          <Alert color="info">{t(`MASONRY.NO_ITEMS`)}</Alert>
        ) : (
          // else show tickets
          <Masonry
            id="faq-listing"
            products={tickets}
            type={FaqNigiri}
            columnClassName='col-12'
            sushiContainerClassName='nigiri-container'
            filters="off"
            showLocation
            showCity
            showSubtitle
          />
        )}
      </Container>

      <Container>
        <ContentrArea id="after-faq-listing" />
      </Container>
    </article>
  );
};

type Props = Readonly<SushiProps>;

const FaqNigiri: React.FC<Props> = ({
  product,
  showSupertitle,
  showTitle,
  showSubtitle,
  showLocation,
  showCity,
  showBrand = false,
  showCategories,
  showTags,
  className,
  // imageClassName,
}) => {
  const { t } = useTranslation();
  const productDetailLink = useProductDetailLink();
  const { categoriesReceived, categories, tagsReceived, tags, locations } = useProductDetails();
  const currentTags = tagsReceived ? product.tag_ids.map((id) => tags[id] ?? null).filter((t) => t !== null) : null;
  const isAbsolute = product.redirect && isAbsoluteURL(product.redirect);

  const [showFaqModal, toggleFaqModal] = useToggle(false);
  const [selectedTicket, setSelectedTicket] = React.useState(null);

  const toggleModal = (productId: number) => {
    toggleFaqModal();
    setSelectedTicket(productId);
  }

  return (
    <Card
      className={classNames(
        'faq-nigiri nigiri mb-3',
        className,
        product.sold_out_status,
        product.tag_ids.map((i) => `tag_${i}`)
      )}
    >
      <CardBody className="faq-nigiri-content nigiri-content">
        {/* <img src={product.image.thumb.url} className={imageClassName} alt={product.title} loading="lazy" /> */}
        <div className="nigiri-date">
          {product.valid_start_on && <DateComponent value={product.valid_start_on} />}
          {product.valid_end_on && product.valid_end_on.toString() !== product.valid_start_on.toString() && (
            <React.Fragment>
              {/* eslint-disable-next-line react/jsx-no-literals */}
              <span>{'—'}</span>
              <DateComponent value={product.valid_end_on} />
            </React.Fragment>
          )}
        </div>
        <div className="nigiri-body" >
          {showSupertitle && product.supertitle && <CardText className="supertitle">{product.supertitle}</CardText>}
          {showTitle && (
            <CardTitle tag="h2" className="title">
              {product.title}
            </CardTitle>
          )}
          {showSubtitle && product.subtitle && <CardText className="subtitle">{product.subtitle}</CardText>}
          {showLocation && (
            <CardText className="location">
              {showCity && <span className="city">{getLocationFromProduct(locations, product).city}{', '}</span>}
              <span className="location-name">{getLocationFromProduct(locations, product).title}</span>
            </CardText>
          )}
          {showCategories &&
            categoriesReceived &&
            product.category_ids.map((id) => (
              <span key={id} className="badge badge-light category">
                {categories[id]?.title}
              </span>
            ))}
          {showTags && currentTags && (
            <div className="nigiri-tags tags">
              {currentTags.map((tag) =>
                tag.icon ? (
                  <span key={tag.id} className={`badge badge-light tag tag-${tag.id} icon-tag`}>
                    <i className={`fal fa-${tag.icon} icon-tag--icon`} />
                    <span className="sr-only">{tag.title}</span>
                  </span>
                ) : (
                  <span
                    key={tag.id}
                    className={`badge badge-light tag tag-${tag.id}`}
                    style={{
                      color: tag.text_color,
                      backgroundColor: tag.bg_color,
                    }}
                  >
                    {tag.title}
                  </span>
                )
              )}
            </div>
          )}

        </div>
        <div className="nigiri-cta">
          <Button
            onClick={()=>toggleModal(product.id)}
            color="primary"
            block
          >
            <span>{t(`FAQ.BUTTON`)}</span>
          </Button>
        </div>
      </CardBody>

      <CardFooter className="p-0">
        <Collapse isOpen={!showFaqModal}>
          {/* nothing to see if collapse is closed */}
        </Collapse>
        <Collapse isOpen={showFaqModal}>
          <div className={`faq faq-${selectedTicket} p-3`}>
            <ContentrArea id={`faq-modal-${selectedTicket}`} />
            <span className="">
              {isAbsolute ?
                <a href={product.redirect} rel="noopener noreferrer" target="_self">{'zur Ticketseite'}</a> :
                <Link to={productDetailLink(product)} >{'zur Ticketseite'}</Link>
              }
            </span>
            {/* <Button
              tag={isAbsolute ? 'a' : Link}
              {...(isAbsolute
                ? { href: product.redirect, rel: 'noopener noreferrer', target: '_self' }
                : { to: productDetailLink(product) })}
              color="secondary"
              className="mb-3 mx-3"
            >
              {'zur Ticket Seite'}
            </Button> */}
          </div>
        </Collapse>
      </CardFooter>
    </Card>
  );
};


const mapStateToProps = (state: AppState) => {
  const { tickets, loading } = state.Tickets;
  return {
    tickets,
    loading,
  };
};

const mapDispatchToProps = {
  receiveTickets,
};

export default React.memo(FAQ);
