import React from 'react';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

import { Container } from '@ttstr/components';
import NewsletterRegister from '@ttstr/components/Customer/NewsletterRegister';

const RegisterNewsletter: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  function handleRegistered() {
    // should probably be its own page.
    history.push('/newsletter/email_sent');
  }

  return (
    <article className="my-5">
      <Container>
        <Helmet>
          <title>{t(`NEWSLETTER.SUBSCRIBE.TITLE`)}</title>
        </Helmet>
        <h1 className="text-center mb-4">{t(`NEWSLETTER.SUBSCRIBE.TITLE`)}</h1>
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            {/* <p className="text-center">
              <strong>{t('NEWSLETTER.SUBSCRIBE.SUBTITLE')}</strong>
            </p>
            <p className="text-justify">{t('NEWSLETTER.SUBSCRIBE.DESCRIPTION')}</p> */}
            <NewsletterRegister onRegistered={handleRegistered} />
          </Col>
        </Row>
      </Container>
    </article>
  );
};

export default React.memo(RegisterNewsletter);
