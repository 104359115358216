import React from 'react';

import memoize from 'lodash.memoize';

import { useTranslation } from 'react-i18next';

import IntlContext, { useIntl } from '@ttstr/components/Intl/IntlContext';

interface Props {
  readonly value: string | number | Date;
  readonly style?: 'with-weekday';
}

const defaultOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
};

const DateComponent: React.FC<Props> = ({ value, style }) => {
  const { t } = useTranslation();
  const { language } = useIntl();
  const date = new Date(value);

  try {
    switch (style) {
      case 'with-weekday':
        return <time dateTime={date.toString()}>{getLongFormatter('de').format(date)}</time>;
      default:
        return <time dateTime={date.toString()}>{getFormatter('de').format(date)}</time>;
    }
  } catch (e) {
    return <span className="text-danger">{t('DATE.INVALID')}</span>;
  }
};

export const getFormatter = memoize((language: string) => {
  return new Intl.DateTimeFormat(language, { ...defaultOptions });
});

export const getLongFormatter = memoize((language: string) => {
  return new Intl.DateTimeFormat(language, { ...defaultOptions, weekday: 'long' });
});

export const getDateTimeFormatter = memoize((language: string, options?: Intl.DateTimeFormatOptions) => {
  return new Intl.DateTimeFormat(language, {
    ...defaultOptions,
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    ...options,
  });
});

export const useDate = () => {
  const { language } = React.useContext(IntlContext);
  return getFormatter(language);
};

export const useDateTime = (options?: Intl.DateTimeFormatOptions) => {
  const { language } = React.useContext(IntlContext);
  return getDateTimeFormatter(language, options);
};

export default React.memo(DateComponent);
