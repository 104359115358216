import React from 'react';
import { useParams, Redirect } from 'react-router-dom';

interface RouteParams {
  readonly id: string;
}

const OldOrderRedirect: React.FC = () => {
  const params = useParams<RouteParams>();

  return <Redirect to={`/orders/${params.id}/xtoken/${window.location.search.replace('?xtoken=', '')}`} />;
};

export default OldOrderRedirect;